import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuizListComponent } from './quiz-list/quiz-list.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { WaitScreenComponent } from './wait-screen/wait-screen.component';
import { QuizScreenComponent } from './quiz-screen/quiz-screen.component';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from './charts/bar-chart.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { FinalLeaderboardComponent } from './final-leaderboard/final-leaderboard.component';
import { QuestionOnlyComponent } from './question-only/question-only.component';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import {
  TransitionGroupComponent,
  TransitionGroupItemDirective,
} from './leaderboard/transition-group';
import { DemoQuizComponent } from './demo-quiz/demo-quiz.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { JwtModule } from '@auth0/angular-jwt';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { EditQuestionComponent } from './edit-question/edit-question.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    QuizListComponent,
    WaitScreenComponent,
    QuizScreenComponent,
    BarChartComponent,
    LeaderboardComponent,
    FinalLeaderboardComponent,
    QuestionOnlyComponent,
    PopupModalComponent,
    TransitionGroupComponent,
    TransitionGroupItemDirective,
    DemoQuizComponent,
    FileUploadComponent,
    EditQuestionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ChartsModule,
    NgxAudioPlayerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    })
  ],
  providers: [AuthGuardService],
  bootstrap: [AppComponent],
})
export class AppModule {}
