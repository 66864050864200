import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx'
import { quizData } from '../interfaces/quizData';
import { DataService } from '../services/data.service';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  fileName: string
  quizData: any
  isUploaded = false
  selectedFile
  constructor(private snackbar: MatSnackBar, private router: Router, private socketService: SocketIOService, private dataService: DataService) { }

  ngOnInit(): void {
  }


  onFileSelected(file: [File]) {
    this.selectedFile = file[0]
    console.log(this.selectedFile);
    this.fileName = this.selectedFile.name
    this.isUploaded = true
  }

  onSubmitFile() {
    let ext = this.fileName.substring(this.fileName.lastIndexOf('.') + 1)
    if (ext !== "xlsx") {
      this.snackbar.open("Only .xlsx, .xls File Types Are Accepted", undefined, { duration: 2500, panelClass: ['snackbar-e'] })
      this.isUploaded = false
      return
    }
    const fileReader = new FileReader()
    fileReader.readAsBinaryString(this.selectedFile)
    fileReader.onload = (event: any) => {
      let data = event.target.result;
      let workbook = XLSX.read(data, { type: 'binary' });
      workbook.SheetNames.forEach(sheet => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { defval: 0 })
        if (data) {
          let quizData: quizData[] = []
          data.forEach(eachItem => {
            if (Number(eachItem['question_type']) == 2) {
              let arr = []
              eachItem['correct_answer'].split(',').forEach(ele => {
                arr.push(ele.toString().trim())
              })
              eachItem['correct_answer'] = arr.join(',')
              console.log(eachItem['correct_answer']);
            } else {
              //incase if correct answer is number convert to string then trim
              eachItem['correct_answer'] = typeof (eachItem['correct_answer']) == 'number' ? Number(eachItem['correct_answer'].toString().trim()) : eachItem['correct_answer'].trim()
            }

            eachItem['option_1'] = typeof(eachItem['option_1']) == 'number' ? Number(eachItem['option_1'].toString().trim()) : eachItem['option_1'].trim()
            eachItem['option_2'] = typeof(eachItem['option_2']) == 'number' ? Number(eachItem['option_2'].toString().trim()) : eachItem['option_2'].trim()
            eachItem['option_3'] = typeof(eachItem['option_3']) == 'number' ? Number(eachItem['option_3'].toString().trim()) : eachItem['option_3'].trim()
            eachItem['option_4'] = typeof(eachItem['option_4']) == 'number' ? Number(eachItem['option_4'].toString().trim()) : eachItem['option_4'].trim()


            let tempObj: quizData = {
              question_id: Number(eachItem['question_id']),
              id: Number(eachItem['question_id']),
              question_type: Number(eachItem['question_type']),
              answer_type: eachItem['answer_type'] || 0,
              question_text: eachItem['question_text'],
              show_media_before_image: Boolean(eachItem['show_media_before_image']),
              question_media: eachItem['question_media'],
              show_video: Number(eachItem['show_video']),
              video_URL: eachItem['video_URL'] || '',
              video_start: eachItem['video_start'] || 0,
              video_end: eachItem['video_end'] || 0,
              show_audio: Number(eachItem['show_audio']),
              audio_URL: eachItem['audio_URL'] || '',
              correct_answer: eachItem['correct_answer'],
              option_1: eachItem['option_1'],
              option_2: eachItem['option_2'],
              option_3: eachItem['option_3'],
              option_4: eachItem['option_4'],
              credit_coin: eachItem['credit_coin'],
              time_in_sec: Number(eachItem['time_in_sec']),
              created_by: Number(eachItem['created_by']),
              qnTag: Number(eachItem['qnTag'])
            }
            quizData.push(tempObj)
          })
          this.quizData = JSON.stringify(quizData);
          this.socketService.sendJsonData(this.quizData);
          this.dataService.quizData = {
            quizData: quizData,
            quizStatus: true
          }
          this.snackbar.open("Json Conversion & Quiz Data Set Successfully !", undefined, { duration: 2500, panelClass: ['snackbar-s'] })
        } else {
          this.snackbar.open("Error converting file to JSON", undefined, { duration: 2500, panelClass: ['snackbar-e'] })
        }
        this.router.navigate(['/quiz-list'])
      })
    }
  }

}
