import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DemoQuizComponent } from './demo-quiz/demo-quiz.component';
import { EditQuestionComponent } from './edit-question/edit-question.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FinalLeaderboardComponent } from './final-leaderboard/final-leaderboard.component';
import { AuthGuardService as AuthGuard } from './guards/auth-guard.service';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { LoginComponent } from './login/login.component';
import { QuestionOnlyComponent } from './question-only/question-only.component';
import { QuizListComponent } from './quiz-list/quiz-list.component';
import { QuizScreenComponent } from './quiz-screen/quiz-screen.component';
import { WaitScreenComponent } from './wait-screen/wait-screen.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'quiz-list', component: QuizListComponent, canActivate: [AuthGuard] },
  {
    path: 'wait-screen',
    component: WaitScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'quiz-screen',
    component: QuizScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'leaderboard',
    component: LeaderboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'final-leaderboard',
    component: FinalLeaderboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'question-only',
    component: QuestionOnlyComponent,
    canActivate: [AuthGuard]
  },
  { path: 'demo-quiz', component: DemoQuizComponent, canActivate: [AuthGuard] },
  {path: 'uploadQn',component:FileUploadComponent,canActivate: [AuthGuard]},
  {
    path: 'editQn',
    component: EditQuestionComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
