<input type="file" class="file-input" (change)="onFileSelected($event.target.files)" accept=".xls,.xlsx" #fileUpload>
<div class="file-upload">
    <mat-card style="border: none;">
        <mat-card-title>
            UPLOAD FILE
        </mat-card-title>
        <mat-divider inset></mat-divider>
        <mat-card-subtitle class="file-name">
            {{fileName || "No file uploaded yet."}}
        </mat-card-subtitle>
        <mat-card-content>
            <mat-icon class="mt-icon">file_upload</mat-icon>
        </mat-card-content>
        <mat-card-footer class="text">
            <button mat-raised-button *ngIf="!isUploaded" color="primary" (click)="fileUpload.click()">Click to upload</button>
            <button mat-raised-button *ngIf="isUploaded" color="primary" (click)="onSubmitFile()">Submit</button>
        </mat-card-footer>
    </mat-card>
</div>
