<div fxLayout="column">
    <div fxLayoutAlign="center center" class="quizList">List Of Available Quiz</div>
    <div fxLayout="column" fxLayoutGap="10px" style="padding: 0px 10px;">
        <mat-card *ngFor='let quiz of quizList'>
            <span> {{quiz}} </span>
            <button mat-raised-button color="primary" class="quiz-list-btns" (click)="onQuizClicked(quiz)">
                Start Quiz
            </button>
            <button mat-raised-button color="warn" class="quiz-list-btns" (click)="onDemoClicked(quiz)">
                Show Demo
            </button>
            <button mat-raised-button color="accent" class="quiz-list-btns"   (click)="onFileUploadClick()">
                Upload File
            </button>
            <button *ngIf="dataService.quizData" mat-raised-button style="background-color:rgb(219, 110, 128)" class="quiz-list-btns"   (click)="onEditQnClick()">
                Edit Questions
            </button>
        </mat-card>
    </div>
</div>