<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css" />

<div fxLayout="column" class="mainDiv">
    <div fxLayout="row" fxLayoutGap="10px" style="background: #fff;padding: 0px 0 10px;" fxLayoutAlign="center center">
        <div fxFlex="20" style="text-align: left">
            <img class="img" src="../../assets/Images/Intro_Page_Kundal.png" style="width: 250px;">
        </div>
        <div fxFlex="60" class="liveQuiz">શ્રી સ્વામિનારાયણ સત્સંગ ક્વીઝ <br><span>પ્રેરક - પ.પૂ. સદ્દ. શ્રીજ્ઞાનજીવનદાસજી સ્વામી <br>કુંડળધામ</span></div>
        
        <div fxFlex="20" style="text-align: right;;">
            <img class="img" src="../../assets/Images/Guruji_Admin_Main_Page.png" style="width: 250px;">
        </div>
    </div>
    <div class="secondary-nav-container"style="padding-top: 30px;">
        <div fxFlex="10" class="totalPlayersDiv">
            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div style="font-size: xxx-large;">{{totalPlayers}}</div>
                <div>Players</div>
            </div>
        </div>
        <div fxFlex="80" class="row" style="display: block;">
            <ul class="list-group" style="display: block;">
                <li class="col-xs-3 list-group-item" *ngFor="let user of userList.slice(0, 36)">
                    <span>{{user}}</span>
                </li>
            </ul>
        </div>
        
        <div fxFlex="10">
            <button mat-raised-button color="primary" class="topcorner" (click)="onStartClicked()">Start</button>
        </div>
    </div>
</div>