<div fxLayout="column" class="questionOnlyMainDiv">
    <div class="nextButtonDiv" style="height: 130px;" fxLayout="row" fxLayoutAlign="end start">
        <button style="background-color: #e76355; margin-top: 185px;" mat-raised-button color="warn"
            (click)="nextClicked()">Next</button>
    </div>
    <div class="question-center-center">
        <div
            *ngIf="(currentQuizQuestion?.show_video == videoQues.noVieo || currentQuizQuestion?.show_video == videoQues.afterQuestion) && currentQuizQuestion?.show_audio == audioQues.noAudio">
            <div *ngIf="currentQuizQuestion?.question_type == 2" style="height: 350px;" fxLayout="row"
                fxLayoutAlign="center center">
                <span style="display: grid;text-align: center;">
                    <img src="../../assets/Images/giphy.gif" style="width: 300px;margin: 0 auto 0px;">
                </span>
            </div>
            <div *ngIf="currentQuizQuestion?.qnTag == 0 " style="height: 350px;" fxLayout="row"
                fxLayoutAlign="center center">
                <span style="display: grid;text-align: center;">
                    <img src="../../assets/Images/normal.png" style="width: 450px;margin: 0 auto 0px;">
                </span>
            </div>
            <div *ngIf="currentQuizQuestion?.qnTag == 1" style="height: 350px;" fxLayout="row"
                fxLayoutAlign="center center">
                <span style="display: grid;text-align: center;">
                    <img src="../../assets/Images/medium.png" style="width: 450px;margin: 0 auto 0px;">
                </span>
            </div>
            <div *ngIf="currentQuizQuestion?.qnTag == 2" style="height: 350px;" fxLayout="row"
                fxLayoutAlign="center center">
                <span style="display: grid;text-align: center;">
                    <img src="../../assets/Images/supe.png" style="width: 450px;margin: 0 auto 0px;">
                </span>
            </div>
            <div class="questionTextDiv" fxLayout="row" fxLayoutAlign="center center">
                <span class="questionSpan">Q.{{currentNumber}} {{currentQuizQuestion?.question_text}}</span>
            </div>            
        </div>
        <div fxLayout="column"
            *ngIf="currentQuizQuestion?.show_video == videoQues.beforeQuestion || currentQuizQuestion?.show_video == videoQues.beforeQuestionVideoOnly"
            style="width: 100%;">
            <div fxFlex="30" class="questionTextDiv" fxLayout="row" fxLayoutAlign="center center" style="position: absolute; top: 0; width: 100%; padding-top: 20px;">
                <span *ngIf="currentQuizQuestion?.show_video == videoQues.beforeQuestion" class="questionSpan">Q.{{currentNumber}} {{currentQuizQuestion?.question_text}}</span>                
            </div>
            <div [ngStyle]="{'height': (currentQuizQuestion?.show_video != videoQues.beforeQuestionVideoOnly) ? '150px' : ''}" >

            </div>
            <div fxLayout="row">
                <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="column">
                        <div *ngIf="currentQuizQuestion?.question_type == 2" style="height: 100px;" fxLayout="row"
                            fxLayoutAlign="center center" fxFlex="50">
                            <span style="display: grid;text-align: center;">
                                <img src="../../assets/Images/giphy.gif" style="width: 300px;margin: 0 auto 0px;">
                            </span>
                        </div>
                    </div>
                </div>
                <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="column">
                        <div *ngIf="currentQuizQuestion?.qnTag == 0 " style="height: 350px;" fxLayout="row"
                        fxLayoutAlign="center center">
                        <span style="display: grid;text-align: center;">
                            <img src="../../assets/Images/normal.png" style="width: 450px;margin: 0 auto 0px;">
                        </span>
                    </div>
                    <div *ngIf="currentQuizQuestion?.qnTag == 1" style="height: 350px;" fxLayout="row"
                        fxLayoutAlign="center center">
                        <span style="display: grid;text-align: center;">
                            <img src="../../assets/Images/medium.png" style="width: 450px;margin: 0 auto 0px;">
                        </span>
                    </div>
                    <div *ngIf="currentQuizQuestion?.qnTag == 2" style="height: 350px;" fxLayout="row"
                        fxLayoutAlign="center center">
                        <span style="display: grid;text-align: center;">
                            <img src="../../assets/Images/supe.png" style="width: 450px;margin: 0 auto 0px;">
                        </span>
                    </div>
                    </div>
                </div>
                <div fxFlex="80" fxLayout="column" fxLayoutAlign="" [ngStyle]="{'margin-left': (currentQuizQuestion?.question_type != 2) ? '-10px' : '', 'margin-top': (currentQuizQuestion?.show_video == videoQues.beforeQuestionVideoOnly && currentQuizQuestion?.question_type != 2) ? '70px' : ''}">
                    <iframe [ngStyle]="{'height': (currentQuizQuestion?.show_video == videoQues.beforeQuestion) ? '600px' : '710px'}" width="1060" class="questionImage" id="iFrameID" [src]='safeURL' frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>                    
                </div>
            </div>
        </div>
        <div fxLayout="column"
            *ngIf="currentQuizQuestion?.show_audio == audioQues.beforeQuestion || currentQuizQuestion?.show_audio == audioQues.beforeQuestionAudioOnly"
            style="width: 100%;">
            <div fxFlex="20" class="questionTextDiv" fxLayout="row" fxLayoutAlign="center center" style="position: absolute; top: 0; width: 100%; padding-top: 20px;">
                <span *ngIf="currentQuizQuestion?.show_audio == audioQues.beforeQuestion" class="questionSpan">Q.{{currentNumber}} {{currentQuizQuestion?.question_text}}</span>                
            </div>
            <div style="height: 200px;">

            </div>
            <div fxLayout="row">
                <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="column">
                        <div *ngIf="currentQuizQuestion?.question_type == 2" style="height: 100px;" fxLayout="row"
                            fxLayoutAlign="center center" fxFlex="50">
                            <span style="display: grid;text-align: center;">
                                <img src="../../assets/Images/giphy.gif" style="width: 300px;margin: 0 auto 0px;">
                            </span>
                        </div>
                    </div>
                </div>
                <div fxFlex="20" fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="column">
                        <div *ngIf="currentQuizQuestion?.qnTag == 0 " style="height: 350px;" fxLayout="row"
                        fxLayoutAlign="center center">
                        <span style="display: grid;text-align: center;">
                            <img src="../../assets/Images/normal.png" style="width: 450px;margin: 0 auto 0px;">
                        </span>
                    </div>
                    <div *ngIf="currentQuizQuestion?.qnTag == 1" style="height: 350px;" fxLayout="row"
                        fxLayoutAlign="center center">
                        <span style="display: grid;text-align: center;">
                            <img src="../../assets/Images/medium.png" style="width: 450px;margin: 0 auto 0px;">
                        </span>
                    </div>
                    <div *ngIf="currentQuizQuestion?.qnTag == 2" style="height: 350px;" fxLayout="row"
                        fxLayoutAlign="center center">
                        <span style="display: grid;text-align: center;">
                            <img src="../../assets/Images/supe.png" style="width: 450px;margin: 0 auto 0px;">
                        </span>
                    </div>
                    </div>
                </div>
                <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" style="margin-right: 350px;">
                    <ngx-audio-player #audio_player [playlist]="msaapPlaylist" [displayTitle]="msaapDisplayTitle"
                        [autoPlay]="false" muted="muted"
                        [displayVolumeControls]="msaapDisplayVolumeControls" [displayArtist]="msaapDisplayArtist"
                        [displayRepeatControls]="msaapDisplayRepeatControls" [displayDuration]="msaapDisplayDuration"
                        [expanded]="true">
                    </ngx-audio-player>
                </div>
                

            </div>
        </div>
        
    </div> 
</div>