import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { quizData } from '../interfaces/quizData';
import { DataService } from '../services/data.service';
import { SocketIOService } from '../services/socketio.service';


@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.css']
})
export class EditQuestionComponent implements OnInit {
  qnForm: FormGroup
  quizEditData: quizData[]
  currentQuizObj: quizData;
  currentQuestion: number = 0;
  totalQuestions: number;
  correctAnsArray = []
  msg: string;
  msgUrl: string;
  constructor(private qzData: DataService, private socketService: SocketIOService,private snackbar:MatSnackBar,private router: Router) {

  }

  ngOnInit(): void {
    this.quizEditData = this.qzData.quizData.quizData
    this.currentQuizObj = this.quizEditData[this.currentQuestion]
    this.totalQuestions = this.quizEditData.length

    this.qnForm = new FormGroup({
      question_id: new FormControl('', [Validators.required]),
      qnTag: new FormControl('', [Validators.required,Validators.max(2), Validators.min(0)]),
      id: new FormControl(),
      answer_type: new FormControl(),
      question_type: new FormControl('', [Validators.required]),
      question_text: new FormControl('', [Validators.required]),
      show_video: new FormControl("", [Validators.max(4), Validators.min(0)]),
      video_URL: new FormControl(),
      video_start: new FormControl('', [Validators.required]),
      video_end: new FormControl('', [Validators.required]),
      show_audio: new FormControl("", [Validators.max(4), Validators.min(0)]),
      audio_URL: new FormControl(),
      show_media_before_image: new FormControl('', [Validators.required]),
      question_media: new FormControl('', [Validators.required]),
      correct_answer: new FormControl('', [Validators.required]),
      credit_coin: new FormControl('', [Validators.required]),
      time_in_sec: new FormControl('', [Validators.required]),
      option_1: new FormControl('', [Validators.required]),
      option_2: new FormControl('', [Validators.required]),
      option_3: new FormControl('', [Validators.required]),
      option_4: new FormControl('', [Validators.required]),
    })
    this.qnForm.controls.correct_answer.markAsTouched()
    this.qnForm.controls.audio_URL.markAsTouched()
    this.qnForm.controls.video_URL.markAsTouched()
    // window['a'] = this.qnForm
  }



  ngAfterViewInit() {
    setTimeout(() => {
      this.updateForm()
      this.correctAnsArray.push(this.qnForm.value['option_1'], this.qnForm.value['option_2'], this.qnForm.value['option_3'], this.qnForm.value['option_4'])
      this.qnForm.controls.correct_answer.setValidators(this.validateMultichoice.bind(this))
      this.qnForm.controls.audio_URL.setValidators(this.validateAudioUrl.bind(this))
      this.qnForm.controls.video_URL.setValidators(this.validateVideoUrl.bind(this))
    });
  }


  onSubmit() {

    let data = {
      question_id: Number(this.qnForm.value['question_id']),
      qnTag: Number(this.qnForm.value['qnTag']),
      id: Number(this.qnForm.value['question_id']),
      question_type: Number(this.qnForm.value['question_type']),
      answer_type: this.qnForm.value['answer_type'] || '0',
      question_text: this.qnForm.value['question_text'],
      show_media_before_image: Boolean(this.qnForm.value['show_media_before_image']),
      question_media: this.qnForm.value['question_media'],
      show_video: Number(this.qnForm.value['show_video']),
      video_URL: this.qnForm.value['video_URL'],
      video_start: this.qnForm.value['video_start'],
      video_end: this.qnForm.value['video_end'],
      show_audio: Number(this.qnForm.value['show_audio']),
      audio_URL: this.qnForm.value['audio_URL'],
      correct_answer: this.qnForm.value['correct_answer'],
      option_1: this.qnForm.value['option_1'].trim(),
      option_2: this.qnForm.value['option_2'].trim(),
      option_3: this.qnForm.value['option_3'].trim(),
      option_4: this.qnForm.value['option_4'].trim(),
      credit_coin: Number(this.qnForm.value['credit_coin']),
      time_in_sec: Number(this.qnForm.value['time_in_sec']),
    }
    this.quizEditData.splice(this.currentQuestion, 1, data)
    let quizDataStringify: any = [...this.quizEditData]
    this.qzData.quizData = {
      quizStatus: true,
      quizData: quizDataStringify
    }
    quizDataStringify = JSON.stringify(quizDataStringify)
    this.socketService.sendJsonData(quizDataStringify);
    this.snackbar.open("Saved Successfully !", undefined, { duration: 1000, panelClass: ['snackbar-s'] })
  }

  onImgChange(url: string) {
    document.getElementById('preview-img').setAttribute('src', url);
  }

  onNextClick() {
    if (this.currentQuestion > this.totalQuestions-2) {
      return
    }else{
      this.currentQuestion++
      this.currentQuizObj = this.quizEditData[this.currentQuestion]
      this.updateForm()
    }
  }

  onPrevClick() {
    if (this.currentQuestion != 0) {
      this.currentQuestion--
      this.currentQuizObj = this.quizEditData[this.currentQuestion]
      this.updateForm()
    }
  }

  onBackClick(){
    this.router.navigate(['/quiz-list'])
  }

  updateForm() {
    this.correctAnsArray = []
    this.correctAnsArray.push(this.currentQuizObj.option_1,this.currentQuizObj.option_2,this.currentQuizObj.option_3,this.currentQuizObj.option_4)
    this.qnForm.patchValue({
      question_id: this.currentQuizObj.question_id,
      qnTag: this.currentQuizObj.qnTag,
      question_type: this.currentQuizObj.question_type.toString(),
      question_text: this.currentQuizObj.question_text,
      show_video: this.currentQuizObj.show_video.toString(),
      video_URL: this.currentQuizObj.video_URL,
      video_start: this.currentQuizObj.video_start || 0,
      video_end: this.currentQuizObj.video_end || 0,
      show_audio: this.currentQuizObj.show_audio.toString(),
      audio_URL: this.currentQuizObj.audio_URL,
      show_media_before_image: this.currentQuizObj.show_media_before_image.toString() || false.toString(),
      question_media: this.currentQuizObj.question_media,
      correct_answer: this.currentQuizObj.correct_answer,
      credit_coin: this.currentQuizObj.credit_coin,
      time_in_sec: this.currentQuizObj.time_in_sec,
      option_1: this.currentQuizObj.option_1,
      option_2: this.currentQuizObj.option_2,
      option_3: this.currentQuizObj.option_3,
      option_4: this.currentQuizObj.option_4
    });
  }

  onkeydown(e) {
    if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
      return;
    } else {
      if (e.key.length === 1) e.preventDefault();
    }
  };

  onChange() {
    this.qnForm.controls.video_URL.updateValueAndValidity()
    this.qnForm.controls.audio_URL.updateValueAndValidity()
  }

  checkValidity() {
    this.correctAnsArray = []
    this.correctAnsArray.push(this.qnForm.controls['option_1'].value, this.qnForm.controls['option_2'].value, this.qnForm.controls['option_3'].value, this.qnForm.controls['option_4'].value)
    this.qnForm.controls.correct_answer.updateValueAndValidity()
  }

  validateMultichoice(control: AbstractControl):{ [key: string]: any } | null {
    if (control.value.includes(',') && this.qnForm.controls.question_type.value == 2) {
      let arr = this.qnForm.controls['correct_answer'].value.split(',')
      for (let i = 0; i < arr.length; i++) {
        let isAnsInclude = this.correctAnsArray.some(ele => arr[i].trim() == ele)
        if (!isAnsInclude) {
          this.msg = 'Answer does not match with given options please check your answers'
          return { multiError: true }
        }
      }
    } else if (!control.value.includes(',') && this.qnForm.controls.question_type.value == 2) {
      this.msg = 'Please add other answers seperated by commas since question type is multichoice'
      return { multiError: true }
    } else if (this.qnForm.controls.question_type.value == 1 && control.value.includes(',')) {
      this.msg = 'Give only one correct answer since question type is single choice'
      return { multiError: true }
    } else {
      return null
    }
  }

  validateAudioUrl(control: AbstractControl) {
    if (this.qnForm.controls.show_audio.value != 0 && control.value.length == 0) {
      this.msgUrl = 'Please enter url'
      return { noUrl: true }
    } else {
      return null
    }
  }

  validateVideoUrl(control: AbstractControl) {
    if (this.qnForm.controls.show_video.value != 0 && control.value.length == 0) {
      this.msgUrl = 'Please enter url'
      return { noUrl: true }
    } else {
      return null
    }
  }
}
