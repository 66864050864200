<div fxLayout="row" style="height: 50vh;">
    <div fxFlex="10" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button mat-mini-fab color="accent" (click)="onBackClick()" style="position:absolute;top: 20px;left: 25px;">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button mat-raised-button color="warn" class="nextQuestionButton" (click)="onPrevClick()">Previous</button>
        </div>
    </div>
    <div fxFlex="100" fxLayoutGap="10px" style="text-align: center;">
        <div fxLayout="row">
            <div fxFlex="100" class="form-container">
                <form [formGroup]="qnForm" (ngSubmit)="onSubmit()" class="form-edit">
                     
                    <mat-radio-group (change)="checkValidity()" formControlName="question_type" class="radio-ip"><mat-label>Qn type: </mat-label>
                        <mat-radio-button value="1">Single</mat-radio-button>
                        <mat-radio-button value="2">Multichoice</mat-radio-button>
                    </mat-radio-group>

                    <mat-form-field appearance="fill">
                        <mat-label>Qn ID</mat-label>
                        <input matInput type="number" formControlName="question_id">
                        <mat-error *ngIf="qnForm.controls.show_video.hasError('max') || qnForm.controls.show_video.hasError('min')">Only number between 0 and 4 allowed</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Qn Tag</mat-label>
                        <input matInput type="number" formControlName="qnTag">
                        <mat-hint>Normal=0&nbsp;&nbsp;Mega=1&nbsp;&nbsp;Super=2&nbsp;&nbsp;</mat-hint>
                    </mat-form-field>


                    <mat-form-field appearance="fill">
                        <mat-label>Question</mat-label>
                        <input matInput type="text" formControlName="question_text">
                    </mat-form-field>

                    <div class="correct-ans">
                        <mat-form-field appearance="fill">
                            <mat-label>Option 1</mat-label>
                            <input matInput type="text" formControlName="option_1" (change)="checkValidity()">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Option 2</mat-label>
                            <input matInput type="text" formControlName="option_2" (change)="checkValidity()">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Option 3</mat-label>
                            <input matInput type="text" formControlName="option_3" (change)="checkValidity()">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Option 4</mat-label>
                            <input matInput type="text" formControlName="option_4" (change)="checkValidity()">
                        </mat-form-field>
                    </div>
                    
                    <div class="audio-container">
                    <mat-form-field appearance="fill">
                        <mat-label>Show video</mat-label>
                        <input matInput type="number" formControlName="show_video" (change)="onChange()" (keydown)="onkeydown($event)">
                        <mat-error *ngIf="qnForm.controls.show_video.hasError('max') || qnForm.controls.show_video.hasError('min')">Only number between 0 and 4 allowed</mat-error>
                        <mat-hint>No video=0&nbsp;&nbsp;Before question=1&nbsp;&nbsp;During question=2&nbsp;&nbsp;<br>After question=3&nbsp;&nbsp;Before question video only=4</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Video url</mat-label>
                        <input matInput type="text" formControlName="video_URL">
                        <mat-error *ngIf="qnForm.controls.video_URL.hasError('noUrl')">{{msgUrl}}</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field appearance="fill">
                        <mat-label>Video start time</mat-label>
                        <input matInput type="text" formControlName="video_start">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Video end time</mat-label>
                        <input matInput type="text" formControlName="video_end">
                    </mat-form-field>
                    </div>

                    <div class="video-container">
                    <mat-form-field appearance="fill">
                        <mat-label>Show audio</mat-label>
                        <input matInput type="number" formControlName="show_audio" (change)="onChange()" (keydown)="onkeydown($event)">
                        <mat-error *ngIf="qnForm.controls.show_video.hasError('max') || qnForm.controls.show_video.hasError('min')">Only number between 0 and 4 allowed</mat-error>
                        <mat-hint>No video=0&nbsp;&nbsp;Before question=1 &nbsp;&nbsp;During question=2&nbsp;&nbsp;<br>After question=3&nbsp;&nbsp;Before question audio only=4</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Audio url</mat-label>
                        <input matInput type="text" formControlName="audio_URL">
                        <mat-error *ngIf="qnForm.controls.audio_URL.hasError('noUrl')">{{msgUrl}}</mat-error>
                    </mat-form-field>
                    </div>
                    
                    <mat-radio-group formControlName="show_media_before_image" style="margin-top:25px;" class="radio-ip"><mat-label>Show media before url: </mat-label>
                        <mat-radio-button value="true" >Yes</mat-radio-button>
                        <mat-radio-button value="false" >No</mat-radio-button>
                    </mat-radio-group>
                    
                    <mat-form-field appearance="fill">
                        <mat-label>Qn media url</mat-label>
                        <input matInput type="text" formControlName="question_media" (change)="onImgChange($event.target.value)">
                    </mat-form-field>

                    <div>
                        <img [src]="currentQuizObj.question_media" id="preview-img">
                    </div>

                    <mat-form-field appearance="fill">
                        <mat-label>Correct Answer</mat-label>
                        <input matInput type="text" formControlName="correct_answer">
                        <mat-error *ngIf="qnForm.controls.correct_answer.hasError('multiError')">{{msg}}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>credit_coin</mat-label>
                        <input matInput type="number" formControlName="credit_coin">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>time_in_sec</mat-label>
                        <input matInput type="number" formControlName="time_in_sec">
                    </mat-form-field>

                    <div class="qn-btn-save">
                    <button type="submit" mat-raised-button color="accent" [disabled]="!qnForm.valid">Save</button>
                </div>
                </form>
            </div>
        </div>
    </div>
    <div fxFlex="10" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button mat-raised-button color="warn" class="nextQuestionButton" (click)="onNextClick()">Next</button>
        </div>
    </div>
</div>